<template>
  <div class="dashboard">

    <div class="wrapper wrapper-content animated fadeInDown">
      <b-row>
        <b-col cols="6" lg="3">
          <div class='ibox float-e-margins dashboard-card'>
            <div class='ibox-title'>
              <h5>{{ $t("multiples.driver.other") }}</h5>
              <div class='pull-right font-bold'>
                <i class='fa fa-user'></i>
              </div>
            </div>
            <div class='ibox-content b-r-xs'>
              <h1 class='no-margins'>{{ dashboardData.active_drivers }}</h1>
              <small>{{ $t("common.number_of_drivers") }}</small>
            </div>
            <router-link :to="{ name: 'drivers' }">
              <div class='ibox-dashboard label-primary'>
                <p class="no-margins">{{ $t("service.drivers.add") }}</p>
              </div>
            </router-link>
          </div>
        </b-col>
        <b-col cols="6" lg="3">
          <div class='ibox float-e-margins dashboard-card'>
            <div class='ibox-title'>
              <h5>{{ $t("transfers.available_transfers") }}</h5>
              <div class='pull-right font-bold'>
                <i class='fa fa-car'></i>
              </div>
            </div>
            <div class='ibox-content b-r-xs'>
              <h1 class='no-margins'>{{ dashboardData.available_transfers }}</h1>
              <small>{{ $t("transfers.available_transfers") }}</small>
            </div>
            <router-link :to="{ name: 'transfers' }">
              <div class='ibox-dashboard label-primary'>
                <p class="no-margins">{{ $t("dashboard.start_booking") }}</p>
              </div>
            </router-link>
          </div>
        </b-col>
        <b-col cols="6" lg="3">
          <div class='ibox float-e-margins dashboard-card'>
            <div class='ibox-title'>
              <h5>{{ $t("transfers.reserved_transfers") }}</h5>
              <div class='pull-right font-bold'>
                <i class='fa fa-bookmark'></i>
              </div>
            </div>
            <div class='ibox-content b-r-xs'>
              <h1 class='no-margins'>{{ dashboardData.open_reservations }}</h1>
              <small>{{ $t("transfers.reserved_transfers") }}</small>
            </div>
            <router-link :to="{ name: 'transfers' }"> <!-- TODO: link to tab2 of transfers page -->
              <div class='ibox-dashboard label-primary'>
                <p class="no-margins">{{ $t("dashboard.assign_drivers_to_transfers") }}</p>
              </div>
            </router-link>
          </div>
        </b-col>
        <b-col cols="6" lg="3">
          <div class='ibox float-e-margins dashboard-card'>
            <div class='ibox-title'>
              <h5>{{ $t("common.revenues") }}</h5>
              <div class='pull-right font-bold'>
                <i class='fa fa-file'></i>
              </div>
            </div>
            <div class='ibox-content b-r-xs'>
              <h1 class='no-margins'>{{ $t("unit.euro") }} {{ dashboardData.money_earned | formatPrice }}</h1>
              <small>{{ $t("dashboard.amount_earned_this_month") }}</small>
            </div>
            <router-link :to="{ name: 'invoices' }">
              <div class='ibox-dashboard label-primary'>
                <p class="no-margins">{{ $t("dashboard.see_more_invoices") }}</p>
              </div>
            </router-link>
          </div>
        </b-col>
      </b-row>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <!-- title -->
            <div class="ibox-title no-borders">
              <h5>{{ $t("transfers.reserved_transfers") }}</h5>
              <div class='pull-right font-bold'>
                <i class='fa fa-suitcase'></i>
              </div>
            </div>
            <!-- table -->
            <div class="ibox-content no-borders">

              <datatable :columns="tab.columns" :fetch-records="tab.fetchFunction" :actions="tab.actions"/>

            </div>
          </div>
        </div>
      </div>
    </div>

    <reservation-modal v-model="reservationModalShow" :id="reservationModalId" :type="reservationModalType"/>
    <default-reservation-cancel-modal v-model="reservationCancelModalShow"/>
    <receipt-modal v-model="receiptModalShow" :id="receiptModalId" :type="receiptModalType"/>
  </div>
</template>

<script>
import Datatable from "@/components/datatable";
import {getDashboardData} from "@/services/dashboard";
import {getReservations} from "@/services/reservations";
import ReservationModal from "@/components/service/reservation_modal";
import DefaultReservationCancelModal from "@/components/default_reservation_cancel_modal";
import ReceiptModal from "@/components/receipt_modal";
import {mapGetters} from "vuex";

export default {
  components: {ReceiptModal, ReservationModal, DefaultReservationCancelModal, Datatable},
  data() {
    return {
      dashboardData: {
        active_drivers: 0,
        available_transfers: 0,
        open_reservations: 0,
        money_earned: 0
      },
      reservationModalId: 0,
      reservationModalShow: false,
      reservationModalType: "show",
      reservationCancelModalShow: false,
      receiptModalId: 0,
      receiptModalShow: false,
      receiptModalType: 'create',
      tab: {
        id: 1,
        label: "transfers.reserved_transfers",
        columns: [
          {
            key: "reference_number",
            label: "id"
          },
          {
            key: "transfer.start_shop.city.name",
            label: "start",
            formatter: (value, key, item) => {
              return value + "<br>" + "<small>" + item?.transfer?.start_shop?.street_name + ", " + item?.transfer?.start_shop?.postcode + "</small>"
            }
          },
          {
            key: "transfer.end_shop.city.name",
            label: "end",
            formatter: (value, key, item) => {
              return value + "<br>" + "<small>" + item?.transfer?.end_shop?.street_name + ", " + item?.transfer?.end_shop?.postcode + "</small>"
            }
          },
          {
            key: "transfer.vehicle.name",
            label: "vehicle",
            formatter: (value, key, item) => {
              if (item.transfer.license_plate) return value + "<br>" + "<small>" + (item.transfer.license_plate || "") + "</small>"
              return value
            }
          },
          {key: "date_range", label: "pickup_time", formatter: this.$options.filters.formatDateRange},
          {
            key: "transfer.distance",
            label: "distance",
            formatter: (value, key, item) => {
              return this.$options.filters.formatDistance(value) + " " + this.$t("unit.km")
            }
          },
          {
            key: "transfer.duration",
            label: "duration",
            formatter: (value, key, item) => {
              return this.$options.filters.formatDurationHours(value) + " " + this.$t('unit.hr') + " " + this.$options.filters.formatDurationMinutes(value) + " " + this.$t('unit.min')
            }
          },
          {
            key: "price",
            label: "price",
            formatter: (value, key, item) => {
              return this.$options.filters.formatPrice(value) + this.$t('unit.euro')
            }
          },
          {key: "mover.name", label: "driver"},
          {key: "actions", label: "actions"},
        ],
        fetchFunction: async (filterQuery) => {
          filterQuery.option = "open"
          const response = await getReservations(filterQuery)
          this.tab.recordsTotal = response.recordsTotal || 0
          response.data.forEach((entry) => {
            entry.actions = [
              {
                label: this.$t('button.show'),
                action: this.showReservationDetailModal
              },
              {
                label: entry.driver_id <= 0 ? this.$t('button.assign') : this.$t('button.reassign'),
                action: this.showReservationEditModal
              },
              {
                label: this.$t('button.start_protocol'),
                action: () => {
                  window.open(entry.start_protocol_url)
                },
                enabled: entry.start_protocol_url
              },
              {
                label: this.$t('button.end_protocol'),
                action: () => {
                  window.open(entry.end_protocol_url)
                },
                enabled: entry.end_protocol_url
              },
              {
                label: this.$t('receipts.manage'),
                action: this.showReceiptModal
              },
              {
                label: this.$t('button.cancel'),
                action: this.showReservationCancelModal,
                enabled: entry.cancelable
              }
            ]
          })
          return response
        },
        actions: [],
        recordsTotal: -1
      },
    }
  },
  mounted() {
    getDashboardData().then((response) => {
      this.dashboardData = response
    })
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
  },
  methods: {
    showReservationDetailModal(entry) {
      this.reservationModalId = entry.id
      this.reservationModalType = "show"
      this.reservationModalShow = true
    },
    showReservationEditModal(entry) {
      this.reservationModalId = entry.id
      this.reservationModalType = "edit"
      this.reservationModalShow = true
    },
    showReservationCancelModal() {
      this.reservationCancelModalShow = true
    },
    showReceiptModal(entry) {
      this.receiptModalId = entry.id
      this.receiptModalShow = true
      this.receiptModalType = entry.allows_receipt_upload ? 'create' : 'view'
    },
  }
}
</script>